import React, { useState, useRef, useEffect, useMemo } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const UrlifyMessage = ({ message, copyToClipboard, formatDateOfTwilioMessage, timeStamp, geekerMsg }) => {
    console.log('message s', message);

    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const segments = message.split(urlRegex);
    const transformedText = segments.map((segment, index) => {
        if (segment.match(urlRegex)) {

            const url = segment;
            return (
                <>
                    <div className="url-container" key={index}>
                        <a style={{ color: '#2196f3' }} href={url} title={url}>
                            <span>{url}</span>
                        </a>
                        <div className="custom-buttons text-center">
                            <div className="custom-buttons-inner">
                                <span
                                    className="button-for-link copy-to-clipboard"
                                    onClick={() => copyToClipboard(url)}
                                    title="Copy to clipboard"
                                >
                                    Copy <ContentCopyIcon />
                                </span>
                                <span
                                    className="button-for-link open-in-new-tab"
                                    onClick={() => window.open(url, '_blank')}
                                    title="Open link in new tab"
                                >
                                    Open <OpenInNewIcon />
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <span key={index} style={{ wordBreak: 'break-word' }}>
                    {geekerMsg ? (<span style={{ color: '#007d81'}}>{segment}</span>):(<span>{segment}</span>)}
                </span>
            );
        }
    });

    return (
        <>
            {transformedText}
            <span className="chat-time-style">
                {timeStamp && formatDateOfTwilioMessage(timeStamp)}
            </span>
        </>
    );

};

export default UrlifyMessage;
